import deepmerge from 'deepmerge'
import { isEqual, isObject, omit, transform } from 'lodash'
import { Context, createContext, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import qs from 'qs'
import { AnyObject } from 'final-form'
import FileType from 'file-type/browser'
import downloadjs from 'downloadjs'
import { AxiosResponse } from 'axios'
import { GREEN_0EB700, ORANGE_FF6F00, RED_DD1212 } from '../constant/colors'
import { CompentencyStatus } from '../services/enum-typed'
import dayjs from 'dayjs'
import { useEffect } from 'react'
const buddhistEra = require('dayjs/plugin/buddhistEra')

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const createCtx = <T extends object>(initValue: T) => {
  return createContext<
    [T, (value: DeepPartial<T>) => void, { reset: () => void; initialValue: T }]
  >([
    initValue,
    (value: DeepPartial<T>) => {},
    {
      reset: () => {},
      initialValue: initValue,
    },
  ])
}

const overwriteMerge = (destinationArray: any, sourceArray: any, options: any) => sourceArray

export const withCtx =
  <T extends AnyObject = AnyObject>(Context: Context<any>) =>
  (Component: React.ElementType) =>
  (props: T) => {
    const initalState = useMemo(() => {
      return (Context as any)._currentValue[0] || {}
    }, [])
    const [state, setState] = useState(initalState)
    const ref = useRef(state)
    const customSetState = useCallback((v: any) => {
      const newState = deepmerge(ref.current, v, { arrayMerge: overwriteMerge })
      if (!isEqual(newState, ref.current)) {
        ref.current = newState
        setState(newState)
      }
    }, [])
    const reset = useCallback(() => {
      if (!isEqual(initalState, ref.current)) {
        ref.current = initalState
        setState(initalState)
      }
    }, [initalState])

    return (
      <Context.Provider value={[state, customSetState, { reset, initialValue: initalState }]}>
        <Component {...props} />
      </Context.Provider>
    )
  }

export const usePreviousPath = () => {
  const location = useLocation()
  const [currPahtname, setCurrPathname] = useState(location.pathname)
  const [currSearch, setCurrSearch] = useState(location.search)
  const [prevPahtname, setPrevPahtname] = useState<string>('')
  const [prevSearch, setPrevSearch] = useState<string>('')

  useEffect(() => {
    setCurrPathname(location.pathname)
    setCurrSearch(location.search)
  }, [location.pathname, location.search])
  useEffect(() => {
    if (currPahtname !== location.pathname) {
      setPrevPahtname(currPahtname)
      setPrevSearch(currSearch)
    }
  }, [currPahtname, currSearch, location])

  return useMemo(() => {
    return prevPahtname ? `${prevPahtname}${prevSearch}` : undefined
  }, [prevPahtname, prevSearch])
}

export const useRouter = <TQuery extends any = any>() => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const query = useMemo(() => {
    return {
      ...qs.parse(location.search.slice(1)),
      ...params,
    } as TQuery
  }, [location.search, params])

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      goBack: history.goBack,
      pathname: location.pathname,
      query,
      match,
      location,
    }
  }, [history.push, history.replace, history.goBack, location, query, match])
}

export const useQueryParams = <T extends object>(excludes?: string[]) => {
  const router = useRouter()
  const setParam = useCallback(
    (value: T) => {
      const _params = omit({ ...router.query, ...value }, ...(excludes || []))
      router.push(`${router.pathname}?${qs.stringify(_params)}`)
    },
    [excludes, router],
  )
  return {
    query: router.query as T,
    setParam,
  }
}

export const blobToFile = async (res: AxiosResponse<Blob>) => {
  const type = await FileType.fromBlob(res.data)
  const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
  return new File([res.data.slice(0, -1)], `${filename}.${type?.ext || ''}`, { type: type?.mime })
}

export const openFileOrDownload = async (res: AxiosResponse<Blob>) => {
  const type = await FileType.fromBlob(res.data)
  const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
  if (type && (type.mime.includes('application/pdf') || type.mime.includes('image/'))) {
    const url = window.URL.createObjectURL(
      new File([res.data.slice(0, -1)], `filename.${type.ext}`, { type: type.mime }),
    )
    const newTab = window.open()
    if (newTab) {
      newTab.location.href = url
    }
  } else {
    downloadjs(res.data, decodeURIComponent(filename))
  }
}

export const downloadFile = async (res: AxiosResponse<Blob>) => {
  const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
  downloadjs(res.data, decodeURIComponent(filename))
}

export const getCompentencyStatusColor = (status: CompentencyStatus) => {
  if (status === 'complete') {
    return GREEN_0EB700
  } else if (status === 'in-progress') {
    return RED_DD1212
  } else if (status === 'not-start') {
    return ORANGE_FF6F00
  }
}

export const getToken = () => {
  // return localStorage.getItem('AUTH_TOKEN')
  // who working on login should do this
  return 'xxx'
}

const defaultMode = ['production', 'uat', 'staging', 'development'] as const

export const isMode = (...mode: (typeof defaultMode)[number][]) => {
  const buildMode = process.env.REACT_APP_MODE as (typeof defaultMode)[number]
  return mode.includes(buildMode)
}

export const isModeDown = (mode: (typeof defaultMode)[number]) => {
  const allowMode = defaultMode.slice(defaultMode.indexOf(mode))
  const buildMode = process.env.REACT_APP_MODE as (typeof defaultMode)[number]
  return allowMode.includes(buildMode)
}

/**
 * example
 * modes = ['production', 'uat', 'staging', 'development']
 * isModeUp('staging') -> when uat, production return true
 */
export const isModeUp = (mode: (typeof defaultMode)[number]) => {
  return !isModeDown(mode)
}

export const monthEnToTh: any = {
  Jan: 'ม.ค.',
  Feb: 'ก.พ.',
  Mar: 'มี.ค.',
  Apr: 'เม.ย.',
  May: 'พ.ค.',
  Jun: 'มิ.ย.',
  Jul: 'ก.ค.',
  Aug: 'ส.ค.',
  Sep: 'ก.ย.',
  Oct: 'ต.ค.',
  Nov: 'พ.ย.',
  Dec: 'ธ.ค.',
}

export const formatDateByLang = (text: string, lang: string) => {
  if (lang === 'th') {
    dayjs.extend(buddhistEra)
    const fmtDate = dayjs(text).format('DD MMM BBBB')
    const piece = fmtDate.split(' ')
    const monthEn = piece[1]
    return `${piece[0]} ${monthEnToTh[monthEn]} ${piece[2]}`
  } else {
    return dayjs(text).format('DD MMM YYYY')
  }
}

export const objectToEntries = <T, K extends string>(o: { [P in K]: T }): [K, T][] => {
  return Object.entries<T>(o).map((cur) => {
    const [k, v] = cur
    const key = k as K
    return [key, v]
  })
}
export const differenceObject = (object: any, base: any) => {
  const changes = (object: any, base: any) => {
    return transform(object, (result: any, value: any, key: any) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}
