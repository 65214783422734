import React, { useMemo } from 'react'
import { ListItem, ListItemIcon, makeStyles, List, useMediaQuery } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'
import { Link, LinkProps } from 'react-router-dom'

import { ReactComponent as HomeIcon } from '../../assets/icon/menu-home.svg'
import { ReactComponent as AspirationIcon } from '../../assets/icon/menu-aspiration.svg'
import { ReactComponent as MyIdpIcon } from '../../assets/icon/menu-my-idp.svg'
import { ReactComponent as IdpReviewIcon } from '../../assets/icon/menu-idp-review.svg'
import { ReactComponent as CapabilityIcon } from '../../assets/icon/menu-capability.svg'
import { ReactComponent as AdminIcon } from '../../assets/icon/menu-admin.svg'

import * as paths from '../../constant/path'
import Text from '../common/Text'
import * as Colors from '../../constant/colors'
import { isMode, isModeUp, useRouter } from '../../utils/helper'
import { useGetManual } from '../../services/document/document-query'
import { useCurrUser } from '../../services/auth/auth-query'
import { breakpoints } from '../../utils/responsive-helper'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles({
  menuSelected: {
    width: '0.3rem',
    height: '2rem',
    background: `${Colors.BLUE_004D99} 0% 0% no-repeat padding-box`,
    borderRadius: '0 6rem 6rem 0px',
    position: 'absolute',
    left: 0,
  },
  listItemIcon: {
    paddingRight: '1.3rem',
    minWidth: 'unset',
  },
  menuListItem: {
    paddingLeft: '2rem',
    height: '4rem',
  },
  icon: {
    '& path': {
      stroke: Colors.GRAY_505E6C,
    },
  },
  iconSelected: {
    '& path': {
      stroke: 'white',
    },
  },
  link: {
    textDecoration: 'none',
  },
  downloadManaul: {
    '& > div': {
      textDecoration: 'underline',
    },
  },
})

type MenuItemProps = {
  text: string
  path: string
  icon?:
    | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  onMenuClick?: () => void
} & Omit<LinkProps, 'to' | 'onClick' | 'className'>

const MenuItem = (props: MenuItemProps) => {
  const { text, icon: SvgIcon, path, onMenuClick, ...restProps } = props
  const classes = useStyle()
  const router = useRouter()
  const { pathname } = router
  const isSelected = useMemo(() => {
    const menuPath = path.split('/')[1]
    const currentMenuPath = pathname.split('/')[1]
    return menuPath === currentMenuPath
  }, [path, pathname])

  return (
    <Link to={path} className={classes.link} onClick={onMenuClick} {...restProps}>
      <ListItem
        button
        key={text}
        className={classes.menuListItem}
        style={{
          backgroundColor: isSelected ? Colors.GREEN_AFBD00 : undefined,
        }}
      >
        {SvgIcon && (
          <ListItemIcon className={classes.listItemIcon}>
            <SvgIcon
              className={isSelected ? classes.iconSelected : classes.icon}
              height="1.3rem"
              width="1.3rem"
            />
          </ListItemIcon>
        )}
        <Text
          size={16}
          weight={isSelected ? 500 : 300}
          color={isSelected ? 'white' : Colors.GRAY_505E6C}
        >
          {text}
        </Text>
      </ListItem>
    </Link>
  )
}

type MenuListProps = {
  onMenuClick?: () => void
}

const MenuList = (props: MenuListProps) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const { mutate: downloadManual } = useGetManual()
  const { data: user } = useCurrUser()
  const isDesktop = useMediaQuery(breakpoints.up('ml'))

  return (
    <List style={{ flex: 1 }}>
      <MenuItem
        path={paths.home()}
        text={t('drawer.home')}
        icon={HomeIcon}
        onMenuClick={props.onMenuClick}
      />
      {user?.positionLevel !== 'md' && user?.positionLevel !== 'fh' && (
        <MenuItem
          path={paths.myAsprireation()}
          text={t('drawer.myAspiration')}
          icon={AspirationIcon}
          onMenuClick={props.onMenuClick}
        />
      )}
      <MenuItem
        path={paths.idp()}
        text={t('drawer.myIdp')}
        icon={MyIdpIcon}
        onMenuClick={props.onMenuClick}
      />
      <MenuItem
        path={paths.idpReview()}
        text={t('drawer.idpReview')}
        icon={IdpReviewIcon}
        onMenuClick={props.onMenuClick}
      />
      <MenuItem
        path={paths.myCapability()}
        text={t('drawer.myCapability')}
        icon={CapabilityIcon}
        onMenuClick={props.onMenuClick}
      />
      {isModeUp('staging') && (
        <MenuItem
          path={paths.competencyDashboard()}
          text={t('competencyDashboard.title')}
          icon={CapabilityIcon}
          onMenuClick={props.onMenuClick}
        />
      )}
      {user?.isAdmin && isDesktop && (
        <MenuItem
          path={paths.admin()}
          text={t('drawer.admin')}
          icon={AdminIcon}
          onMenuClick={props.onMenuClick}
        />
      )}
      <ListItem
        button
        key={'คู่มือการใช้งานระบบ'}
        className={`${classes.menuListItem} ${classes.downloadManaul}`}
        style={{}}
        onClick={() => {
          downloadManual()
        }}
      >
        <Text size={16} color={Colors.GRAY_505E6C}>
          {t('drawer.manual')}
        </Text>
      </ListItem>

      {isMode('development') && (
        <MenuItem
          path={paths.wiki()}
          text={t('drawer.wiki')}
          icon={HomeIcon}
          onMenuClick={props.onMenuClick}
        />
      )}
    </List>
  )
}

export default MenuList
